<template>
  <div class="page">
    <div class="to-back" @click="toBack">
      <img src="@/static/image/ic_back_dart@2x.png" alt="" />
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import MapService from "../../utils/map-service";
let addressIcon = require("@/static/image/of_ic_address_shop_m@2x.png");
import GoogleMap from "@/utils/google-map"
export default {
  name: "shop-address-page",
  data() {
    return {
      circle: null,
      mapService: null,
      lat: 0,
      lng: 0,
      // lat: 22.930257,
      // lng: 113.388995,
      index: 0,
      show: false,
      markerArr: [],
    };
  },
  created() {
    // console.log(this.$route)
    let {lat,lng,name} = this.$route.query
    this.lat = lat *1
    this.lng = lng *1
    console.log(this.lat,this.lng)
    // this.lat = lat
  },
  mounted() {
     GoogleMap.InitGoogle().then(res=>{
      this.initMap();
    }).catch(err=>{
      console.log('err')
    })
  },
  methods: {
    toBack(){
      uni.navigateBack()
    },
    initMap() {
      let that = this;
      let maps = new google.maps.Map(document.getElementById("map"), {
        zoom: 16,
        center: { lat: that.lat, lng: that.lng },
        disableDefaultUI: true,
        clickableIcons: false, //阻止點選開啟預設資訊視窗
      });
      this.mapService = new MapService(
        maps,
        new google.maps.LatLng(that.lat, that.lng)
      );
       const marker = new google.maps.Marker({
          anchorPoint: new google.maps.Point(0, 0),
          // position: {
          //  anchorPoint: new google.maps.LatLng(item.lat, item.lng),
          //   // collisionBehavior:true
          // },
          position: new google.maps.LatLng(that.lat, that.lng),
          map: maps,
          icon: {
            url: addressIcon,
            scaledSize: new google.maps.Size(42, 48),
            origin: new google.maps.Point(0, 0), // origin
          },
          zIndex: 1,
        });
        console.log(marker,'marker')
    },
  },
  watch: {
    index(index) {
      console.log(index);
      this.markerArr.forEach((item, i) => {
        if (i == index) {
          item.marker.setOptions({
            icon: {
              url: addressIcon,
              scaledSize: new google.maps.Size(45, 45),
              origin: new google.maps.Point(0, 0), // origin
              // anchor: new google.maps.Point(0, 0),
            },
          });
        } else {
          item.marker.setOptions({
            icon: {
              url: addressIcon,
              scaledSize: new google.maps.Size(24, 24),
              origin: new google.maps.Point(0, 0), // origin
              // anchor: new google.maps.Point(0, 0),
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.point {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  background-color: #000;
  z-index: 1;
}
.page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .to-back {
    position: fixed;
    left: 15px;
    top: 57px;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:10;
    img {
      width: 9px;
      height: 15px;
    }
  }
  .shop-info {
    width: 100%;
    padding: 22px 0;
    padding-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    color: #333;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    .title {
      padding: 0 17px;
      font-size: 19px;
      font-weight: 600;
    }
    .subhead {
      padding: 0 17px;
      font-size: 15px;
    }
    .address {
      padding: 0 17px;
      margin-top: 8px;
      font-size: 15px;
      color: #9a9a9a;
      margin-bottom: 28px;
    }
    .submit {
      box-shadow: 0px -1px 4px 0px rgba(209, 209, 209, 0.5);
      padding: 15px 16px 48px;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 47px;
        background-color: #008edc;
        color: #fff;
        font-size: 16px;
        border-radius: 8px;
      }
      // padding-top: 15px;
      // padding-bottom: 48px;
    }
  }
  #map {
    width: 100%;
    height: 100%;
  }
}
.icon {
  // position: absolute;
  // top:20px ;
  transform: translateY(100px);
}
</style>
