<template>
  <div>
    <div
      id="map"
      @touchmove="touchmove"
      @touchstart="touchstart"
      @touchend="touchend"
    ></div>
    <div class="center" :class="isTouching ? 'touch' : ''">
      <div class="icon" :class="isRange ? '' : 'is-not-range'">{{ name }}</div>
    </div>
    <!-- <div class="center">台灣</div> -->
    <div class="message">
      <div class="tobegin" v-if="showToBegin" @click="toBegin">
        <img src="@/static/image/of_ic_address_recovery@2x.png" alt="" />
      </div>
      <div class="title">確認派送地址</div>
      <div class="toast">
        {{
          isRange
            ? "移動圖釘以突出顯示正確的大門或入口，以便物流士更快的派送訂單商品。"
            : "您距離此地址較遠。"
        }}
      </div>
      <div class="button" @click="changeAddress" :class="isRange ? '' : 'gray'">
        確認位置
      </div>
    </div>
  </div>
</template>

<script>
import MapService from "../../utils/map-service";
import GoogleMap from "@/utils/google-map"
export default {
  mounted() {
    GoogleMap.InitGoogle().then(res=>{
      this.initMap();
    }).catch(err=>{
      console.log('err')
    })
  },
  data() {
    return {
      lat: 0, //22.930257
      lng: 0, //113.388995
      name: "",
      isRange: true,
      mapService: null,
      circle: null,
      isTouching: false,
      showToBegin: false,
      changeLatLng:{
        lat:'',
        lng:''
      }
    };
  },
  created() {
    console.log('created')
    this.lat = this.$route.query.lat * 1;
    this.lng = this.$route.query.lng * 1;
    this.changeLatLng.lat = this.$route.query.lat * 1;
    this.changeLatLng.lng = this.$route.query.lng * 1;
    this.name = this.$route.query.name;
  },
  methods: {
    touchmove() {
      this.resetCircle();
      this.showToBegin = true;
    },
    touchstart() {
      this.isTouching = true;
      this.resetCircle();
    },
    touchend() {
      if (this.circle) {
        let circleLatLng = this.circle.getCenter();
        let meters = this.mapService.distance2(
          circleLatLng,
          this.mapService.googlemap.getCenter()
        );
        if (meters > 300) {
          this.isRange = false;
          this.circle.setOptions({
            strokeColor: "#CF3533",
          });
        } else {
          this.resetCircle();
        }
      }
      this.isTouching = false;
    },
    // 重置圓形樣式
    resetCircle() {
      this.isRange = true;
      if (this.circle) {
        this.circle.setOptions({
          strokeColor: "#000000",
        });
      }
    },
    toBegin() {
      this.mapService.googlemap.panTo(
        new google.maps.LatLng(this.lat, this.lng)
      );
      this.resetCircle()
      this.isRange = true
    },
    initMap() {
      let that = this;

      // 初始化地圖
      let maps = new google.maps.Map(document.getElementById("map"), {
        zoom: 16,
        center: { lat: that.lat, lng: that.lng },
        clickableIcons: false, //阻止點選開啟預設資訊視窗
        disableDefaultUI: true,
      });
      // 建立地圖方法
      this.mapService = new MapService(
        maps,
        new google.maps.LatLng(that.lat, that.lng)
      );
      // 畫原型
      this.circle = this.mapService.drawCircle({
        strokeColor: "#000000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#000000",
        fillOpacity: 0,
        center: new google.maps.LatLng(that.lat, that.lng),
        radius: 300,
      });

      // 監聽中心點變化
      let timer = null;
      this.mapService.googlemap.addListener("center_changed", function (e) {
        console.log(e,'e')
        let latLng = that.mapService.googlemap.getCenter();
        that.changeLatLng.lat = latLng.lat()
        that.changeLatLng.lng = latLng.lng()
        // let circleLatLng = that.circle.getCenter();
        
        // let meters = that.mapService.distance2(
        //   circleLatLng,
        //   that.mapService.googlemap.getCenter()
        // );
        // clearTimeout(timer);
        // timer = null;
        // if (!timer) {
        //   timer = setTimeout(() => {
        //     if (meters > 300) {
        //       that.isRange = false;
        //       that.circle.setOptions({
        //         strokeColor: "#CF3533",
        //       });
        //     } else {
        //       that.isRange = true;
        //       that.circle.setOptions({
        //         strokeColor: "#000000",
        //       });
        //     }
        //   });
        // }
      });
    },

    drawMarker(maps, point, icon, address) {
      let markers = [];
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(point.lat, point.lng),
        map: maps,
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(24, 24),
          origin: new google.maps.Point(0, 0), // origin
          anchor: new google.maps.Point(0, 0),
        },
      });

      markers.push(marker);
      let contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h3 id="firstHeading" class="firstHeading f-13">' +
        address +
        "</h3>" +
        "</div>";
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      google.maps.event.addListener(marker, "click", function () {
        infowindow.open(maps, marker);
      });
      infowindow.open(maps, marker);

      return marker;
    },
    changeAddress() {
      let that = this;
      if(!this.isRange) return
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.getEnv(function (res) {
          console.log("當前環境：" + JSON.stringify(res));
          console.log(window.location.href);
        });
      });
      uni.postMessage({
        data: {
          lng: that.changeLatLng.lng,
          lat: that.changeLatLng.lat,
        },
      });
      uni.navigateBack()
    },
  },
};
</script>

<style lang="less" scoped>
#map {
  height: 100vh;
}
.message {
  width: 100%;
  padding: 15px 20px;
  background-color: #fff;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .tobegin {
    width: 30px;
    height: 30px;
    // background-color: #000;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    right: 20px;
    img{
      width: 100%;
    }
  }
  .title {
    font-size: 16px;
    color: #333;
  }
  .toast {
    font-size: 12px;
    color: #444444;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .button {
    background-color: #444;
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.gray {
      background-color: #f6f6f6;
      color: #c1c1c1;
    }
  }
}
.center {
  width: 2px;
  height: 2px;
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: #000;
  transform: translateX(-1px);
  .icon {
    // width: 30px;
    height: 40px;
    // width: 50px;
    padding: 0 20px;
    white-space: nowrap;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-size: 12px;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    // top: 280px;
    bottom: 15px;
    transform: translateX(-50%);
    &.is-not-range {
      background-color: #cf3533;
      color: #fff;
      &::after {
        background-color: #cf3533;
      }
    }
    &::after {
      content: "";
      width: 2px;
      height: 15px;
      display: block;
      background-color: #000;
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.touch {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    .icon {
      bottom: 25px;
    }
  }
}
</style>
