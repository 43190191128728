var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.barOpacity > -2.1
        ? _c("div", {
            staticClass: "head-bar",
            class: { upToDown: _vm.barOpacity > -2 },
            style: { opacity: _vm.barOpacity + 0.2 },
          })
        : _vm._e(),
      _c("div", { staticClass: "back-icon" }, [
        _c("img", {
          attrs: {
            src: require("@/static/image/ic_return_lucency.png"),
            alt: "",
          },
          on: { click: _vm.goBack },
        }),
      ]),
      _c("div", { attrs: { id: "map" } }),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "content",
          style: { top: _vm.hiddenOrder ? "70vh" : "0" },
          on: {
            touchmove: _vm.downOrderInfo,
            touchstart: _vm.downOrderInfo,
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.downOrderInfo.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "order",
              style: { marginTop: _vm.hiddenOrder ? "0" : "70vh" },
              on: {
                touchstart: function ($event) {
                  $event.stopPropagation()
                },
                touchmove: function ($event) {
                  $event.stopPropagation()
                  _vm.hiddenOrder = false
                },
              },
            },
            [
              _c("div", { staticClass: "order-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "status",
                    on: {
                      click: function ($event) {
                        _vm.detail.status == "untake" ||
                        _vm.detail.status == "ungridtake"
                          ? _vm.clickTakeCode()
                          : ""
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.statusStr[_vm.detail.status])),
                    ]),
                    _vm.detail.type == "shop_take"
                      ? _c("div", { staticClass: "time" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.shopTakeOrderDescStr[_vm.detail.status]
                              ) +
                              " "
                          ),
                        ])
                      : _c("div", { staticClass: "time pb-5" }, [
                          _vm._v(_vm._s(_vm.orderDescStr[_vm.detail.status])),
                        ]),
                    _vm.detail.type == "delivery" &&
                    _vm.detail.channel &&
                    _vm.detail.channel == "pack" &&
                    _vm.detail.status == "expressing"
                      ? _c("div", { staticClass: "pin-code" }, [
                          _vm._v(" 請在外送員送達時出示 PIN Code: "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.detail && _vm.detail.pinCode
                                  ? _vm.detail.pinCode
                                  : ""
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.detail.status == "untake" ||
                    _vm.detail.status == "ungridtake"
                      ? _c("div", { staticClass: "buttons" }, [
                          _vm.detail.status == "untake" &&
                          _vm.detail.sourceType != "omni"
                            ? _c("div", { staticClass: "deliveryman" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/static/image/of_ic_order_qrcode@3x.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" 取貨條碼 "),
                              ])
                            : _vm._e(),
                          _vm.detail.status == "ungridtake" &&
                          _vm.detail.sourceType != "omni"
                            ? _c("div", { staticClass: "deliveryman" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/static/image/of_ic_order_qrcode@3x.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" 櫃取條碼 "),
                              ])
                            : _vm._e(),
                          _vm.detail.status == "untake" &&
                          _vm.detail.sourceType == "omni"
                            ? _c("div", { staticClass: "deliveryman" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/static/image/take-codee.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" 到店取餐 "),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm.packageList && _vm.packageList.length >= 1
                  ? _c(
                      "div",
                      { staticClass: "spu" },
                      [
                        _c("div", { staticClass: "store" }, [_vm._v(" 包裹 ")]),
                        _vm._l(_vm.packageList, function (item, index) {
                          return [
                            _c("order-detail-spu-item", {
                              attrs: { item: item },
                            }),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "spu" },
                  [
                    _c(
                      "div",
                      { staticClass: "store", on: { click: _vm.goShop } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.detail &&
                                _vm.detail.role &&
                                _vm.detail.role.name
                                ? _vm.detail.role.name
                                : ""
                            ) +
                            " "
                        ),
                        _c("img", {
                          attrs: {
                            src: require("@/static/image/ic_next@2x.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _vm._l(_vm.spuList, function (item, index) {
                      return [
                        _c("order-detail-spu-item", { attrs: { item: item } }),
                      ]
                    }),
                    _c(
                      "div",
                      { staticClass: "price-list" },
                      [
                        _vm._l(_vm.detail.feeList, function (part) {
                          return _c("div", { staticClass: "discount" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(part.name) +
                                  " " +
                                  _vm._s(
                                    part.quantity && part.quantity >= 2
                                      ? "x" + part.quantity
                                      : ""
                                  )
                              ),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "bold",
                                class: part.color ? part.color : "",
                              },
                              [_vm._v("NT$ " + _vm._s(part.price))]
                            ),
                          ])
                        }),
                        _vm.detail && _vm.detail.finalPrice
                          ? _c("div", { staticClass: "discount pb-5" }, [
                              _c("div", [_vm._v("總付款金額")]),
                              _c("div", { staticClass: "bold" }, [
                                _vm._v("NT$ " + _vm._s(_vm.detail.finalPrice)),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  2
                ),
                _c("div", { staticClass: "detail" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("訂單詳情")]),
                  _c("div", { staticClass: "address item" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.detail.type == "self_take"
                              ? "取貨"
                              : _vm.detail.type == "delivery"
                              ? "外送"
                              : "店取"
                          ) +
                          "地址 "
                      ),
                    ]),
                    _vm.detail.type == "shop_take" && _vm.detail.pickShop
                      ? _c("div", { staticClass: "address-info" }, [
                          _c("div", { staticClass: "detail-address" }, [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail && _vm.detail.pickShop
                                      ? _vm.detail.pickShop.address
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail.pickShop &&
                                      _vm.detail.pickShop.name
                                      ? _vm.detail.pickShop.name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm.detail.type == "delivery" && _vm.detail.orderAddress
                      ? _c("div", { staticClass: "address-info" }, [
                          _c("div", { staticClass: "detail-address" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.detail && _vm.detail.orderAddress
                                    ? _vm.detail.orderAddress.address
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "user-info" }, [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail && _vm.detail.orderAddress
                                      ? _vm.detail.orderAddress.name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail && _vm.detail.orderAddress
                                      ? _vm.detail.orderAddress.phone
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      : _c("div", { staticClass: "address-info" }, [
                          _c("div", { staticClass: "detail-address" }, [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail && _vm.detail.role
                                      ? _vm.detail.role.address
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.detail.role && _vm.detail.role.name
                                      ? _vm.detail.role.name
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                  ]),
                  _c("div", { staticClass: "remaker item" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("備註")]),
                    _c("div", { staticClass: "value t-r" }, [
                      _vm._v(_vm._s(_vm.detail.remark)),
                    ]),
                  ]),
                  _c("div", { staticClass: "time item" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("下單時間")]),
                    _c("div", { staticClass: "value t-r" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$util.formatDate(
                              _vm.detail.createTime,
                              "yyyy-MM-dd hh:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "orderid item" }, [
                    _c("div", { staticClass: "name" }, [_vm._v("訂單編號")]),
                    _c("div", { staticClass: "value t-r" }, [
                      _vm._v(_vm._s(_vm.detail.orderNumber)),
                    ]),
                  ]),
                  _vm.detail.qcOrderInfo && _vm.detail.qcOrderInfo.invoiceTypeNo
                    ? _c("div", { staticClass: "orderid item" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v("統一編號"),
                        ]),
                        _c("div", { staticClass: "value t-r" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.detail.qcOrderInfo.invoiceTypeNo) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm.expressTime
                  ? _c("div", { staticClass: "shipping" }, [
                      _c("div", { staticClass: "title" }, [_vm._v("配送資訊")]),
                      _c("div", { staticClass: "time" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v("送達時間"),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.expressTime)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _vm.takeGoodsCodeShow
        ? _c("div", { staticClass: "popup-wrap showAnimation" }, [
            _c("div", { staticClass: "content-bg-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("取貨條碼")]),
              _vm.detail && _vm.detail.orderNumber
                ? _c("div", { staticClass: "order-number" }, [
                    _vm._v(_vm._s(_vm.detail.orderNumber)),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "code-box" },
                [
                  _c("vue-qr", {
                    attrs: { text: _vm.detail.orderToken, size: 175 },
                  }),
                ],
                1
              ),
              _vm.detail.status == "ungridtake"
                ? _c(
                    "div",
                    { staticClass: "trigger", on: { click: _vm.triggerTake } },
                    [_vm._v(" 切"), _c("br"), _vm._v("換 ")]
                  )
                : _vm._e(),
            ]),
            _c("img", {
              staticClass: "close-icon",
              attrs: { src: require("@/static/image/of_ic_close@2x.png") },
              on: {
                click: function ($event) {
                  _vm.takeGoodsCodeShow = false
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.ungridTakeCodeShow
        ? _c("div", { staticClass: "popup-wrap showAnimation" }, [
            _c("div", { staticClass: "content-bg-box" }, [
              _c("div", { staticClass: "title" }, [_vm._v("櫃取條碼")]),
              _vm.detail && _vm.detail.orderNumber
                ? _c("div", { staticClass: "order-number" }, [
                    _vm._v(_vm._s(_vm.detail.orderGrid.password)),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "code-box" },
                [
                  _c("vue-qr", {
                    attrs: { text: _vm.detail.orderGrid.password, size: 175 },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "trigger", on: { click: _vm.triggerTake } },
                [_vm._v(" 切"), _c("br"), _vm._v("換 ")]
              ),
            ]),
            _c("img", {
              staticClass: "close-icon",
              attrs: { src: require("@/static/image/of_ic_close@2x.png") },
              on: {
                click: function ($event) {
                  _vm.ungridTakeCodeShow = false
                },
              },
            }),
          ])
        : _vm._e(),
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.isShowTakeMealsNumber, "z-index": "100" },
          on: {
            click: function ($event) {
              _vm.isShowTakeMealsNumber = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "wrapper",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", { staticClass: "block" }, [
                _c(
                  "div",
                  {
                    staticClass: "close-icon",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.isShowTakeMealsNumber = false
                      },
                    },
                  },
                  [
                    _c("van-icon", {
                      attrs: { name: "cross", color: "#999999", size: "16" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "name text-center" }, [
                  _vm._v("取餐號"),
                ]),
                _c("div", { staticClass: "take-meals-number text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detail.orderNumber &&
                          _vm.detail.orderNumber.substr(
                            _vm.detail.orderNumber.length - 6
                          )
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "text text-center" }, [
                  _vm._v("請將取餐號告知店員"),
                ]),
                _c("div", { staticClass: "text text-center" }, [
                  _vm._v("取餐後按下「確認取餐」完成取餐"),
                ]),
                _c("div", { staticClass: "btns" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button text-center close",
                      on: {
                        click: function ($event) {
                          _vm.isShowTakeMealsNumber = false
                        },
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "button text-center confirm",
                      on: { click: _vm.takeMeals },
                    },
                    [_vm._v(" 確認取餐 ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }