var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "to-back", on: { click: _vm.toBack } }, [
      _c("img", {
        attrs: { src: require("@/static/image/ic_back_dart@2x.png"), alt: "" },
      }),
    ]),
    _c("div", { attrs: { id: "map" } }),
    _vm.show
      ? _c("div", { staticClass: "shop-info" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.locationArr[_vm.index].role.name)),
          ]),
          _c("div", { staticClass: "subhead" }, [_vm._v("便利店")]),
          _c("div", { staticClass: "address" }, [
            _vm._v(_vm._s(_vm.locationArr[_vm.index].role.address)),
          ]),
          _c("div", { staticClass: "submit" }, [
            _c(
              "div",
              {
                staticClass: "button",
                class:
                  _vm.locationArr[_vm.index].status == "closed" ? "closed" : "",
                on: { click: _vm.submit },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.locationArr[_vm.index].status == "opening"
                      ? "選取"
                      : "休息中"
                  )
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }