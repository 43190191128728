export default class GMapService {
  constructor(googlemap, LatLng) {
    if (!googlemap) {
      throw new Error("map must not be null");
    }
    this.googlemap = googlemap;
    this.LatLng = LatLng;
  }

  drawCircle(options) {
    options.map = this.googlemap;
    const cityCircle = new google.maps.Circle(options);
    return cityCircle;
    // strokeColor: "#000000",
    // strokeOpacity: 0.8,
    // strokeWeight: 2,
    // fillColor: "#000000",
    // fillOpacity: 0,
    // center: new google.maps.LatLng(that.lat, that.lng),
    // radius: 300,
  }

  distance2(ondLatLng, newLatLng) {
    var meters = google.maps.geometry.spherical.computeDistanceBetween(
      ondLatLng,
      newLatLng
    );

    // 單位：米
    return meters;
  }
}
