<template>
  <div class="page">
    <div
      class="head-bar"
      v-if="barOpacity > -2.1"
      :class="{ upToDown: barOpacity > -2 }"
      :style="{ opacity: barOpacity + 0.2 }"
    ></div>
    <div class="back-icon">
      <img @click="goBack" src="@/static/image/ic_return_lucency.png" alt="" />
    </div>

    <div id="map"></div>

    <!-- <div
      class="mask"
      @click="showMap"
      v-show="moveHeight < -350"
      :style="{ opacity: ((moveHeight + 350 + 1) / -sysHeight).toFixed(2) }"
    ></div> -->
    <div
      class="content"
      @touchmove="downOrderInfo"
      @touchstart="downOrderInfo"
      @click.self="downOrderInfo"
      ref="content"
      :style="{ top: hiddenOrder ? '70vh' : '0' }"
    >
      <div
        class="order"
        @touchstart.stop
        @touchmove.stop="hiddenOrder = false"
        :style="{ marginTop: hiddenOrder ? '0' : '70vh' }"
      >
        <div class="order-detail">
          <div
            class="status"
            @click="(detail.status == 'untake'||detail.status == 'ungridtake') ? clickTakeCode() : ''"
          >
            <div class="title">{{ statusStr[detail.status] }}</div>
            <div class="time" v-if="detail.type == 'shop_take'">
              {{ shopTakeOrderDescStr[detail.status] }}
            </div>
            <div class="time pb-5" v-else>{{ orderDescStr[detail.status] }}</div>

            <div class="pin-code" v-if="detail.type=='delivery'&&detail.channel&&detail.channel=='pack'&&detail.status=='expressing'">
              請在外送員送達時出示 PIN Code: <span>{{detail&&detail.pinCode?detail.pinCode:""}}</span>
            </div>

            <!--  注意这里的显示判断，现在只做了untake状态的显示判断 -->
            <div class="buttons" v-if="detail.status == 'untake'||detail.status == 'ungridtake'">
              <!-- 暫時去除 -->
              <!-- <div class="store" @click="contactShop">
              <img
                src="@/static/image/of_ic_order_call@2x.png"
                alt=""
              />連絡商家
            </div> -->
              <!-- 暫時去除聯絡物流士 -->
              <!-- <div
                class="deliveryman"
                @click="contactExpress"
                v-if="detail.type == 'delivery' && false"
              >
                <img src="@/static/image/of_ic_order_call@2x.png" alt="" />
                連絡物流士
              </div> -->
              <div
                class="deliveryman"
                v-if="detail.status == 'untake' && detail.sourceType != 'omni'"
              >
                <img src="@/static/image/of_ic_order_qrcode@3x.png" alt="" />
                取貨條碼
              </div>
              <div
                class="deliveryman"
                v-if="detail.status == 'ungridtake'&& detail.sourceType != 'omni'"
              >
                <img src="@/static/image/of_ic_order_qrcode@3x.png" alt="" />
                櫃取條碼
              </div>
              <div
                class="deliveryman"
                v-if="detail.status == 'untake' && detail.sourceType == 'omni'"
              >
                <img src="@/static/image/take-codee.png" alt="" />
                到店取餐
              </div>
            </div>
          </div>


          
          <div class="spu" v-if="packageList&&packageList.length>=1">
            <div class="store">
              包裹
            </div>

            <template  v-for="(item, index) in packageList">
              <order-detail-spu-item :item="item"></order-detail-spu-item>
            </template>

          </div>


          <div class="spu">
            <div class="store" @click="goShop">
              {{ detail && detail.role && detail.role.name? detail.role.name: "" }}
              <img src="@/static/image/ic_next@2x.png" alt="" />
            </div>

            
            <template  v-for="(item, index) in spuList">
              <order-detail-spu-item :item="item"></order-detail-spu-item>
            </template>
            

            <div class="price-list">

              <div class="discount" v-for="part in detail.feeList">
                <span>{{ part.name }}  {{part.quantity&&part.quantity>=2?"x"+part.quantity:""}}</span>
                <span :class="part.color?part.color:''" class="bold">NT$ {{part.price}}</span>
              </div>

              <div class="discount pb-5" v-if="detail&&detail.finalPrice">
                <div>總付款金額</div>
                <div class="bold">NT$ {{ detail.finalPrice }}</div>
              </div>
            </div>

          </div>




          <div class="detail">
            <div class="title">訂單詳情</div>
            <div class="address item">
              <div class="name">
                {{
                  detail.type == "self_take"
                    ? "取貨"
                    : detail.type == "delivery"
                    ? "外送"
                    : "店取"
                }}地址
              </div>

              <div
                class="address-info"
                v-if="detail.type == 'shop_take' && detail.pickShop"
              >
                <div class="detail-address">
                  <div>
                    {{
                      detail && detail.pickShop ? detail.pickShop.address : ""
                    }}
                  </div>
                  <div>
                    {{
                      detail.pickShop && detail.pickShop.name
                        ? detail.pickShop.name
                        : ""
                    }}
                  </div>
                </div>
              </div>

              <div
                class="address-info"
                v-else-if="detail.type == 'delivery' && detail.orderAddress"
              >
                <div class="detail-address">
                  {{
                    detail && detail.orderAddress
                      ? detail.orderAddress.address
                      : ""
                  }}
                </div>
                <div class="user-info">
                  <div>
                    {{
                      detail && detail.orderAddress
                        ? detail.orderAddress.name
                        : ""
                    }}
                  </div>
                  <div>
                    {{
                      detail && detail.orderAddress
                        ? detail.orderAddress.phone
                        : ""
                    }}
                  </div>
                </div>
              </div>

              <div class="address-info" v-else>
                <div class="detail-address">
                  <div>
                    {{ detail && detail.role ? detail.role.address : "" }}
                  </div>
                  <div>
                    {{
                      detail.role && detail.role.name ? detail.role.name : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="remaker item">
              <div class="name">備註</div>
              <div class="value t-r">{{ detail.remark }}</div>
            </div>
            <div class="time item">
              <div class="name">下單時間</div>
              <div class="value t-r">
                {{ $util.formatDate(detail.createTime, "yyyy-MM-dd hh:mm") }}
              </div>
            </div>
            <div class="orderid item">
              <div class="name">訂單編號</div>
              <div class="value t-r">{{ detail.orderNumber }}</div>
            </div>
            <div
              class="orderid item"
              v-if="detail.qcOrderInfo && detail.qcOrderInfo.invoiceTypeNo"
            >
              <div class="name">統一編號</div>
              <div class="value t-r">
                {{ detail.qcOrderInfo.invoiceTypeNo }}
              </div>
            </div>
          </div>

          <div class="shipping" v-if="expressTime">
            <div class="title">配送資訊</div>
            <div class="time">
              <div class="name">送達時間</div>
              <div class="value">{{ expressTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-wrap showAnimation" v-if="takeGoodsCodeShow">
      <div class="content-bg-box">
        <div class="title">取貨條碼</div>
        <div class="order-number" v-if="detail&&detail.orderNumber">{{detail.orderNumber}}</div>
        <div class="code-box">
          <vue-qr :text="detail.orderToken" :size="175"></vue-qr>
        </div>
        <div class="trigger" v-if="detail.status=='ungridtake'" @click="triggerTake">
          切<br>換
        </div>
      </div>
      <img
        class="close-icon"
        src="@/static/image/of_ic_close@2x.png"
        @click="takeGoodsCodeShow = false"
      />
    </div>
    <div class="popup-wrap showAnimation" v-if="ungridTakeCodeShow">
      <div class="content-bg-box">
        <div class="title">櫃取條碼</div>
        <div class="order-number" v-if="detail&&detail.orderNumber">{{detail.orderGrid.password}}</div>
        <div class="code-box">
          <vue-qr :text="detail.orderGrid.password" :size="175"></vue-qr>
        </div>
        <div class="trigger" @click="triggerTake">
          切<br>換
        </div>
      </div>
      <img
        class="close-icon"
        src="@/static/image/of_ic_close@2x.png"
        @click="ungridTakeCodeShow = false"
      />
    </div>

    <!-- 益欣商家取餐號 -->
    <van-overlay
      :show="isShowTakeMealsNumber"
      @click="isShowTakeMealsNumber = false"
      z-index="100"
    >
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="close-icon"  @click.stop="isShowTakeMealsNumber = false">
            <van-icon
              name="cross"
              color="#999999"
              size="16"
             
            />
          </div>
          <div class="name text-center">取餐號</div>
          <div class="take-meals-number text-center">
            {{
              detail.orderNumber &&
              detail.orderNumber.substr(detail.orderNumber.length - 6)
            }}
          </div>
          <div class="text text-center">請將取餐號告知店員</div>
          <div class="text text-center">取餐後按下「確認取餐」完成取餐</div>
          <div class="btns">
            <div
              class="button text-center close"
              @click="isShowTakeMealsNumber = false"
            >
              取消
            </div>
            <div class="button text-center confirm" @click="takeMeals">
              確認取餐
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import MapService from "../../utils/map-service";
const AddressIcon = require("@/static/image/of_ic_order_aimlocation@2x.png");
const UserIcon = require("@/static/image/of_ic_comment_rider@2x.png");
const CursorIcon = require("@/static/image/of_ic_order_location@2x.png");

const AvatarIcon = require("@/static/image/of_ic_order_user@2x.png");
import GoogleMap from "@/utils/google-map";
import { OrderApi } from "@/api";
import VueQr from "vue-qr";
import { Toast, Overlay, Icon } from "vant";
import Vue from "vue";
import md5 from "../../utils/md5";
import "vant/lib/overlay/style";
import "vant/lib/icon/style";
import orderDetailSpuItem from "@/components/order-detail-spu-item";
Vue.use(Overlay);
Vue.use(Icon);
export default {
  name: "order-page",
  data() {
    return {
      mapService: null,
      lat: "",
      lng: "",
      oldTouchY: 0,
      nowTouchY: 0,
      moveHeight: -250, // 滑動偏移量
      orginOffset: -250, // 滑動之前偏移量
      detailOffset: 0, // 訂單詳情偏移量
      orginDetailOffset: 0, // 滑動前詳情偏移量
      orderDetailHeigt: 0, // 訂單詳情高度
      isOpen: true, // 閥
      timer: null,
      timer1: null,
      y: 0,

      centerLat: "",
      centerLng: "",

      //! api enter
      orderId: "",
      detail: {},
      statusStr: {
        cancel: "已取消",
        expressing: "配送中",
        finish: "已完成",
        unaccept: "待接單",
        unexpress: "待配送",
        unpay: "待支付",
        unready: "準備中",
        untake: "待取貨",
        ungridtake: "待櫃取",
      },
      orderDescStr: {
        cancel: "訂單已取消",
        expressing: "您的訂單已經送出，物流士正在外送中",
        finish: "訂單已完成",
        unaccept: "訂單待接單",
        unexpress: "訂單待配送",
        unpay: "訂單待支付",
        unready: "訂單準備中",
        untake: "您的商品已準備完畢,到店後請出示取貨碼取貨",
        ungridtake: "您的商品已入櫃，到店後請至指定櫃位取貨",
      },
      shopTakeOrderDescStr: {
        cancel: "訂單已取消",
        expressing: "您的訂單已經送出，物流士正在外送中",
        finish: "訂單已完成",
        unaccept: "訂單待接單",
        unexpress: "訂單待配送",
        unpay: "訂單待支付",
        unready: "訂單準備中",
        untake: "您的商品已送達門市,請出示取貨碼取貨",
        ungridtake: "您的商品已入櫃，到店後請至指定櫃位取貨",
      },
      takeGoodsCodeShow: false,
      ungridTakeCodeShow: false,
      courier: {
        lat: "",
        lng: "",
      },
      shop: {
        lat: "",
        lng: "",
        shopName: "",
      },
      isFinishCenter: false,
      expressTime: "",
      hiddenOrder: false,
      timer: null,
      isShowTakeMealsNumber: false,
      takeMealsNumber: "",
    };
  },
  // components: { Overlay },
  created() {},
  async mounted() {
    this.orderId = this.$route.query.id;
    this.getDetail();
    // this.getDetailTest();
    document.body.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  },
  methods: {
    // 宜欣商家點擊自取
    takeMeals() {
      console.log("21312");
      // return
      // let timestamp = this.$route.query.timestamp
      // let sign = this.$route.query.sign

      // if(!sign||!timestamp) return
      let id = this.orderId;
      let timestamp = new Date().getTime().toString().substr(0, 10);
      let sign = md5.hex_md5_32(this.orderId + timestamp + "qcommerce");

      OrderApi.take({ id, sign, timestamp })
        .then((res) => {
          console.log(res, "res");
          uni.postMessage({
            data: {
              eventName: "orderFinish",
              orderId: this.orderId,
            },
          });
        })
        .catch((err) => {});
    },
    mapCreate() {
      GoogleMap.InitGoogle()
        .then((res) => {
          this.initMap();
        })
        .catch((err) => {
          console.log("err");
        });
      // 訂單高度
      // this.$nextTick(() => {
      //   let orderDOM = document.getElementsByClassName("order")[0];
      //   orderDOM.addEventListener("touchstart", this.toushstart);
      //   orderDOM.addEventListener("touchmove", this.toushmove);
      //   orderDOM.addEventListener("touchend", this.toushend);
      // });
    },
    downOrderInfo() {
      this.$refs.content.scrollTo(0, 0);
      setTimeout(() => {
        this.hiddenOrder = true;
      }, 30);
    },
    upOrderInfo() {
      // this.hiddenOrder = false;
      // clearTimeout(this.timer);
      // this.timer = setTimeout(() => {
      //   if (this.$refs.content.scrollTop < 10) {
      //     this.downOrderInfo();
      //   }
      // }, 100);
    },
    getDetailTest() {
      let resTest = {
        code: 0,
        data: {
          id: 1749,
          type: "self_take",
          // type: "shop_take",
          // type: "delivery",
          createTime: "2022-06-08T10:10:29.000+00:00",
          updateTime: "2022-06-08T10:10:29.000+00:00",
          roleId: 5136,
          pickRoleId: null,
          userId: 45,
          sourceId: "",
          sourceType: "omni",
          roleCode: "00011269",
          status: "untake",
          orderNumber: "FA17P-300GA2",
          spuQuantity: 1,
          expressType: "platform",
          expressFee: 52.0,
          brokeragePercent: 5,
          brokerage: 2.7,
          totalPrice: 106.0,
          finalPrice: 106.0,
          spuDiscount: 0.0,
          expressDiscount: 0.0,
          settleExpressFee: 0.0,
          settleSpuFee: 51.3,
          shopRefund: 0.0,
          userRefund: 0.0,
          packageFee: 5.0,
          payType: "family_mart_pay",
          payTime: "2022-06-08T10:11:38.000+00:00",
          pickableTime: null,
          finishTime: null,
          remark: "",
          express: "",
          expressNum: "",
          expressTime: null,
          isComment: 0,
          orderToken: "qcommerce-qrcode://K/yi4BD1JWi++glBp+gT3w==",
          role: {
            id: 5136,
            createTime: "2022-04-28T08:33:38.000+00:00",
            updateTime: "2022-06-09T16:00:18.000+00:00",
            business: "of",
            inviteRoleId: null,
            inviteSysUserId: null,
            userId: null,
            sysUserId: null,
            parentId: null,
            type: null,
            categoryId: null,
            name: "淡水新民店",
            logo: "https://storage.googleapis.com/tfm-delivery-development.appspot.com/images/16511377494897396.png",
            phone: "0226223099",
            areaCode: "003200030026",
            province: "台灣省",
            city: "新北市",
            lat: 25.17903416,
            lng: 121.4367229,
            district: "淡水區",
            address: "新北市淡水區新民街１８０之６號",
            authenticationType: "person",
            isEnable: 1,
          },
          shop: {
            id: 5132,
            createTime: "2022-04-28T08:33:38.000+00:00",
            updateTime: "2022-06-10T05:50:00.000+00:00",
            roleId: 5136,
            roleCode: "00011269",
            vendorCode: "FA17P",
            cover:
              "https://storage.googleapis.com/tfm-delivery-development.appspot.com/images/16518941609097847.png",
            type: "fami_shop",
            pattern: "market",
            score: 5.0,
            spuCount: 5,
            orderCount: 0,
            totalSaleAmount: null,
            refundOrderCount: 0,
            rejectOrderCount: 0,
            finishOrderCount: 0,
            depositAmount: null,
            restDay: null,
            lat: 25.17903416,
            lng: 121.4367229,
            preparationTime: 30,
            priceSymbol: "$",
            isSelfTake: 1,
            selfTakeMinimum: 0,
            isShopTake: 0,
            shopTakeMinimum: 0,
            isDelivery: 1,
            expressType: "platform",
            deliveryMinimum: 0,
            isPhysicalShop: 1,
            isVirtualShop: 0,
            physicalDeliveryRange: 6.0,
            virtualDeliveryRange: 5.0,
            selfTakeRange: null,
            keyword: "淡水新民店",
            status: "opening",
            packageFee: 5.0,
            isEnable: 1,
            role: null,
            orderCommentCount: null,
            shopTimeList: null,
            distance: null,
            categoryName: null,
            isCollect: null,
            goodsTime: null,
            expressFee: 0,
            isRestDay: false,
            isAllDay: false,
          },
          orderAddress: {
            id: 1571,
            createTime: "2022-06-08T10:10:29.000+00:00",
            updateTime: null,
            orderId: 1749,
            name: "Aabb",
            phone: "0920220102",
            province: "台灣省",
            city: "新北市",
            district: "淡水區",
            detail: "中正路一段130號",
            room: "11",
            building: "777",
            address: "台灣省新北市淡水區中正路一段130號",
            lat: 25.179185,
            lng: 121.422489,
            tag: "",
            zipCode: "251",
            remark: "在門口見面",
          },
          orderExpress: {
            acceptTime: "",
            extra: "",
            orderId: 0,
            platform: {},
            riderAvatar: "",
            riderId: "",
            riderLat: 25.178285,
            riderLng: 121.445489,
            riderName: "",
            riderPhone: "",
            sourceId: "",
            startExpressTime: "",
          },
          orderItemList: [
            {
              id: 2967,
              createTime: "2022-06-08T10:10:29.000+00:00",
              updateTime: "2022-06-08T10:10:29.000+00:00",
              roleId: 5136,
              orderId: 1749,
              spuId: 6952,
              spuName: "ＬＯＴＴＥ",
              spuCover:
                "https://prod-img-dev.dynacloud.site/product/0000051.png",
              quantity: 1,
              unitPrice: 49.0,
              stockPrice: null,
              discount: 0.0,
              propertyValues: "",
              type: 0,
              noStockStrategy: "remove",
              remark: "",
              refundQuantity: 0,
            },
          ],
          pickShop: {
            lat: 25.177185,
            lng: 121.422489,
            name: "asdffad",
          },
          userInfo: null,
          user: null,
          feeList: [
            { name: "配送費", price: 52.0 },
            { name: "購物袋", price: 5.0 },
          ],
        },
        pagination: null,
        message: "",
        serverTime: 1654840214,
      };

      let resSelfTake = {
        code: 0,
        data: {
          id: 2563,
          createTime: "2022-06-29T09:08:41.000+00:00",
          updateTime: "2022-06-29T09:10:31.000+00:00",
          roleId: 7891,
          pickRoleId: null,
          userId: 123,
          sourceId: "",
          sourceType: "fami_shop",
          roleCode: "00010001",
          status: "untake",
          orderNumber: "FA053-BYA64J",
          spuQuantity: 1,
          type: "self_take",
          expressType: "platform",
          expressFee: 0.0,
          brokeragePercent: 5,
          brokerage: 1.15,
          totalPrice: 23.0,
          finalPrice: 23.0,
          spuDiscount: 0.0,
          expressDiscount: 0.0,
          settleExpressFee: 0.0,
          settleSpuFee: 21.85,
          shopRefund: 0.0,
          userRefund: 0.0,
          packageFee: 5.0,
          payType: "family_mart_pay",
          payTime: null,
          pickableTime: null,
          finishTime: null,
          remark: "",
          express: "",
          expressNum: "",
          expressTime: null,
          isComment: 0,
          orderToken: "FamilyDelivery|1|1FG3Rrkqpnm4aCLsAIKaEA==",
          role: {
            id: 7891,
            createTime: "2022-06-28T02:13:32.000+00:00",
            updateTime: "2022-07-05T16:00:00.000+00:00",
            business: "of",
            inviteRoleId: null,
            inviteSysUserId: null,
            userId: null,
            sysUserId: null,
            parentId: null,
            type: null,
            categoryId: null,
            name: "淡水登峰店",
            logo: "https://prod-img-dev.dynacloud.site/shop/common.png?v=202206141433",
            phone: "0228085461",
            areaCode: "003200030026",
            province: "台灣省",
            city: "新北市",
            district: "淡水區",
            address: "新北市淡水區中正東路二段２７號２樓",
            authenticationType: "person",
            isEnable: 1,
          },
          shop: {
            id: 7725,
            createTime: "2022-06-28T02:13:33.000+00:00",
            updateTime: "2022-07-05T16:00:00.000+00:00",
            roleId: 7891,
            roleCode: "00010001",
            vendorCode: "FA053",
            cover:
              "https://prod-img-dev.dynacloud.site/shop/common.png?v=202206141433",
            type: "fami_shop",
            pattern: "market",
            score: 5.0,
            spuCount: 0,
            orderCount: 0,
            totalSaleAmount: null,
            refundOrderCount: 0,
            rejectOrderCount: 0,
            finishOrderCount: 0,
            depositAmount: null,
            restDay: "",
            lat: 25.15678029,
            lng: 121.4594507,
            preparationTime: 30,
            priceSymbol: "$",
            isSelfTake: 1,
            selfTakeMinimum: 0,
            isShopTake: 1,
            shopTakeMinimum: 99,
            isDelivery: 1,
            expressType: "platform",
            deliveryMinimum: 99,
            isPhysicalShop: 1,
            isVirtualShop: 0,
            physicalDeliveryRange: 3.0,
            virtualDeliveryRange: 3.0,
            selfTakeRange: null,
            keyword: null,
            status: "closed",
            packageFee: 5.0,
            isEnable: 1,
            role: null,
            orderCommentCount: null,
            shopTimeList: null,
            distance: null,
            categoryName: null,
            isCollect: null,
            goodsTime: null,
            expressFee: 0,
            isRestDay: false,
            isAllDay: false,
            isPause: 0,
          },
          orderAddress: null,
          orderItemList: [
            {
              id: 4472,
              createTime: "2022-06-29T09:08:41.000+00:00",
              updateTime: "2022-06-29T09:08:41.000+00:00",
              roleId: 7891,
              orderId: 2563,
              spuId: 19851,
              spuName: "白飯",
              spuCover:
                "https://prod-img-dev.dynacloud.site/product/0950149.png?v=202206241029",
              quantity: 1,
              unitPrice: 18.0,
              stockPrice: null,
              discount: 0.0,
              propertyValues: "",
              type: 0,
              noStockStrategy: "remove",
              remark: "",
              refundQuantity: 0,
            },
          ],
          userInfo: null,
          user: null,
          feeList: [
            {
              name: "購物袋",
              price: 5.0,
            },
          ],
          pickShop: {
            name: "淡水登峰店",
            phone: "0228085461",
            province: "台灣省",
            city: "新北市",
            district: "淡水區",
            address: "新北市淡水區中正東路二段２７號２樓",
            lat: 25.15678029,
            lng: 121.4594507,
          },
          orderExpress: null,
        },
        pagination: null,
        message: "",
        serverTime: 1657100689,
      };

      let resShopTake = {
        code: 0,
        data: {
          id: 2558,
          createTime: "2022-06-29T07:01:32.000+00:00",
          updateTime: "2022-06-29T07:14:36.000+00:00",
          roleId: 7891,
          pickRoleId: 8428,
          userId: 123,
          sourceId: "",
          sourceType: "fami_shop",
          roleCode: "00010001",
          status: "untake",
          orderNumber: "FA053-BYA64H",
          spuQuantity: 4,
          type: "shop_take",
          expressType: "platform",
          expressFee: 20.0,
          brokeragePercent: 10,
          brokerage: 38.5,
          totalPrice: 405.0,
          finalPrice: 405.0,
          spuDiscount: 0.0,
          expressDiscount: 0.0,
          settleExpressFee: 0.0,
          settleSpuFee: 346.5,
          shopRefund: 0.0,
          userRefund: 0.0,
          packageFee: 5.0,
          payType: "family_mart_pay",
          payTime: null,
          pickableTime: null,
          finishTime: null,
          remark: "",
          express: "",
          expressNum: "",
          expressTime: null,
          isComment: 0,
          orderToken: "FamilyDelivery|1|ReeGhKfZT7sNjOjsZmuu1g==",
          role: {
            id: 7891,
            createTime: "2022-06-28T02:13:32.000+00:00",
            updateTime: "2022-07-05T16:00:00.000+00:00",
            business: "of",
            inviteRoleId: null,
            inviteSysUserId: null,
            userId: null,
            sysUserId: null,
            parentId: null,
            type: null,
            categoryId: null,
            name: "淡水登峰店",
            logo: "https://prod-img-dev.dynacloud.site/shop/common.png?v=202206141433",
            phone: "0228085461",
            areaCode: "003200030026",
            province: "台灣省",
            city: "新北市",
            district: "淡水區",
            address: "新北市淡水區中正東路二段２７號２樓",
            authenticationType: "person",
            isEnable: 1,
          },
          shop: {
            id: 7725,
            createTime: "2022-06-28T02:13:33.000+00:00",
            updateTime: "2022-07-05T16:00:00.000+00:00",
            roleId: 7891,
            roleCode: "00010001",
            vendorCode: "FA053",
            cover:
              "https://prod-img-dev.dynacloud.site/shop/common.png?v=202206141433",
            type: "fami_shop",
            pattern: "market",
            score: 5.0,
            spuCount: 0,
            orderCount: 0,
            totalSaleAmount: null,
            refundOrderCount: 0,
            rejectOrderCount: 0,
            finishOrderCount: 0,
            depositAmount: null,
            restDay: "",
            lat: 25.15678029,
            lng: 121.4594507,
            preparationTime: 30,
            priceSymbol: "$",
            isSelfTake: 1,
            selfTakeMinimum: 0,
            isShopTake: 1,
            shopTakeMinimum: 99,
            isDelivery: 1,
            expressType: "platform",
            deliveryMinimum: 99,
            isPhysicalShop: 1,
            isVirtualShop: 0,
            physicalDeliveryRange: 3.0,
            virtualDeliveryRange: 3.0,
            selfTakeRange: null,
            keyword: null,
            status: "closed",
            packageFee: 5.0,
            isEnable: 1,
            role: null,
            orderCommentCount: null,
            shopTimeList: null,
            distance: null,
            categoryName: null,
            isCollect: null,
            goodsTime: null,
            expressFee: 0,
            isRestDay: false,
            isAllDay: false,
            isPause: 0,
          },
          orderAddress: null,
          orderItemList: [
            {
              id: 4461,
              createTime: "2022-06-29T07:01:32.000+00:00",
              updateTime: "2022-06-29T07:01:32.000+00:00",
              roleId: 7891,
              orderId: 2558,
              spuId: 17423,
              spuName: "蜂蜜水８７０ｍｌ",
              spuCover:
                "https://prod-img-dev.dynacloud.site/product/0046869.png?v=202206241028",
              quantity: 4,
              unitPrice: 95.0,
              stockPrice: null,
              discount: 0.0,
              propertyValues: "",
              type: 0,
              noStockStrategy: "remove",
              remark: "",
              refundQuantity: 0,
            },
          ],
          userInfo: null,
          user: null,
          feeList: [
            {
              name: "配送費",
              price: 20.0,
            },
            {
              name: "購物袋",
              price: 5.0,
            },
          ],
          pickShop: {
            name: "淡水新摩天店",
            phone: "0226255587",
            province: "台灣省",
            city: "新北市",
            district: "淡水區",
            address: "新北市淡水區新春街１８４號",
            lat: 25.18086764,
            lng: 121.4434899,
          },
          orderExpress: {
            id: 273,
            createTime: "2022-06-29T06:25:44.000+00:00",
            updateTime: "2022-06-29T07:08:37.000+00:00",
            orderId: 2558,
            platform: "global_business",
            sourceId: "-18494098",
            riderId: null,
            riderName: null,
            riderPhone: "123321",
            riderAvatar: null,
            riderLat: null,
            riderLng: null,
            extra:
              '{"code":200,"status":true,"message":"送件成功!","response":{"id":33541,"send_name":"陳佑艾","send_phone":"0939514015","meals_price":0,"order_no":"FA2Q5-BYA64Y","remarks":"請告知商家是【快電商外送訂單】取件，報出訂單編號，取件後請核對商品總數量\\n訂單編號: FA2Q5-BYA64Y\\n商家名稱: 華視店\\n商家電話: 0227310238\\n商品總數量: 4件\\n送達樓層: 全家全家\\n收件人: 陳佑艾，0939514015","request_id":18494098,"request_id_m":"18494098","status":1,"newrequest_id":44796,"newrequest_id_m":"44796"},"success":true}',
            acceptTime: "2022-06-29T06:25:44.000+00:00",
            startExpressTime: null,
          },
        },
        pagination: null,
        message: "",
        serverTime: 1657101817,
      };

      let res = resTest;

      this.detail = res.data;
      this.shop.lng =
        res.data.type == "shop_take" && res.data.pickShop
          ? res.data.pickShop.lng
          : res.data.shop.lng;
      this.shop.lat =
        res.data.type == "shop_take" && res.data.pickShop
          ? res.data.pickShop.lat
          : res.data.shop.lat;
      this.shop.shopName =
        res.data.type == "shop_take" && res.data.pickShop
          ? res.data.pickShop.name
          : "店鋪";
      this.mapCreate();
      return;
    },
    getDetail() {
      OrderApi.detail(this.orderId)
        .then((res) => {
          this.detail = res.data;
          this.shop.lng =
            res.data.type == "shop_take" &&
            res.data.pickShop &&
            res.data.pickShop.lat
              ? res.data.pickShop.lng
              : res.data.shop.lng;
          this.shop.lat =
            res.data.type == "shop_take" &&
            res.data.pickShop &&
            res.data.pickShop.lat
              ? res.data.pickShop.lat
              : res.data.shop.lat;
          this.shop.shopName =
            res.data.type == "shop_take" ? res.data.pickShop.name : "店鋪";
          //地圖：建立並渲染
          this.mapCreate();
          if (this.detail.status == "expressing") {
            this.getGuessTime();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toushstart(e) {
      this.oldTouchY = e.touches[0].clientY;
    },
    toushmove(e) {
      this.nowTouchY = e.touches[0].clientY;
    },
    toushend() {
      this.orginOffset = this.moveHeight;
      if (this.orginOffset <= -this.sysHeight) {
        this.isOpen = false;
        this.orginDetailOffset = this.detailOffset;
      } else {
        this.isOpen = true;
        this.orginDetailOffset = 0;
      }
    },
    showMap() {
      clearInterval(this.timer);
      this.timer = null;
      this.timer = setInterval(() => {
        clearTimeout(this.timer1);
        this.timer1 = null;
        this.timer1 = setTimeout(() => {
          this.orginOffset = -250;
        }, 20);
        if (this.moveHeight - 30 > -200) {
          clearInterval(this.timer);
          this.timer = null;
          this.moveHeight = -200;
          return;
        }
        this.moveHeight += 30;
      }, 10);
    },
    initMap() {
      if (!google) {
        return;
      }
      console.log("create map");
      let that = this;
      if (
        this.detail.type == "delivery" &&
        this.detail.orderExpress &&
        this.detail.orderExpress.riderLng
      ) {
        this.centerLng = this.detail.orderExpress.riderLng;
        this.centerLat = this.detail.orderExpress.riderLat;
      } else if (
        this.detail.type == "shop_take" &&
        this.detail.pickShop &&
        this.detail.pickShop.lat
      ) {
        this.centerLat = this.detail.pickShop.lat;
        this.centerLng = this.detail.pickShop.lng;
      } else {
        this.centerLat = this.detail.shop.lat;
        this.centerLng = this.detail.shop.lng;
      }
      let zoomSize = this.getLocationDistance();
      let maps = new google.maps.Map(document.getElementById("map"), {
        zoom: zoomSize,
        center: { lat: this.centerLat, lng: this.centerLng },
        disableDefaultUI: true,
        clickableIcons: false, //阻止點選開啟預設資訊視窗
      });
      this.mapService = new MapService(
        maps,
        new google.maps.LatLng(this.lat, this.lng)
      );
      let type = this.detail.type;

      // 物流士地址
      if (
        (type == "delivery" || type == "shop_take") &&
        this.detail.orderExpress &&
        this.detail.orderExpress.riderLat
      ) {
        this.CursorMap = this.darwUserAddress(
          this.detail.orderExpress.riderLat,
          this.detail.orderExpress.riderLng,
          "物流士",
          CursorIcon,
          UserIcon
        );
        setInterval(async () => {
          let res = await OrderApi.express(this.orderId);
          if (res.code == 0 && res.data && res.data.riderLat) {
            this.CursorMap.setOptions({
              position: new google.maps.LatLng(
                res.data.riderLat,
                res.data.riderLng
              ),
            });
          }
        }, 180000);
      }

      // 代收點地址，pickShop
      if (
        type == "shop_take" &&
        this.detail.pickShop &&
        this.detail.pickShop.lat
      ) {
        this.pickShopMap = this.darwDeliveryAddress(
          this.detail.pickShop.lat,
          this.detail.pickShop.lng,
          "代收點",
          CursorIcon
        );
      }
      if (
        type == "delivery" &&
        this.detail.orderAddress &&
        this.detail.orderAddress.lat
      ) {
        this.userMap = this.darwUserAddress(
          this.detail.orderAddress.lat,
          this.detail.orderAddress.lng,
          "自己",
          CursorIcon,
          AvatarIcon
        );
      }

      // 商家地址
      if (this.detail.shop && this.detail.shop.lat) {
        this.deliverymap = this.darwDeliveryAddress(
          this.detail.shop.lat,
          this.detail.shop.lng,
          this.detail.role.name,
          CursorIcon
        );
      }

      this.mapService.googlemap.addListener("center_changed", () => {
        if (that.isFinishCenter == false) {
          that.mapService.googlemap.panTo(
            new google.maps.LatLng(this.centerLat, this.centerLng)
          );
        }
      });

      setTimeout(() => {
        this.isFinishCenter = true;
        google.maps.event.clearListeners(
          this.mapService.googlemap,
          "center_changed"
        );
      }, 1024);
    },
    darwUserAddress(lat, lng, text, cursorIconSrc, userIconSrc) {
      let that = this;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: that.mapService.googlemap,
        icon: {
          url: AddressIcon,
          scaledSize: new google.maps.Size(24, 24),
        },
      });
      let contentString =
        '<div id="content">' +
        '<div id="siteNotice" class="map-poin"><img class="avatar" src="' +
        userIconSrc +
        '">' +
        text +
        '<img class="cursor" src="' +
        cursorIconSrc +
        '" ></div></div>';
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
      // debugger
      infowindow.open(this.mapService.googlemap, marker);
      return marker;
    },
    darwDeliveryAddress(lat, lng, text, cursorIconSrc) {
      let that = this;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: that.mapService.googlemap,
        icon: {
          url: AddressIcon,
          scaledSize: new google.maps.Size(24, 24),
        },
      });
      let contentString =
        '<div id="content">' +
        '<div id="siteNotice" class="map-poin">' +
        text +
        '<img class="cursor" src="' +
        cursorIconSrc +
        '" ></div></div>';
      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(this.googlemap, marker);
      return marker;
    },
    triggerTake(){
      if(this.ungridTakeCodeShow&&!this.takeGoodsCodeShow){
        this.ungridTakeCodeShow = false
        this.takeGoodsCodeShow = true;
      }else if(!this.ungridTakeCodeShow&&this.takeGoodsCodeShow){
        this.ungridTakeCodeShow = true
        this.takeGoodsCodeShow = false;
      }
    },
    clickTakeCode() {
      // 店取顯示取貨碼
      if(this.detail.status=='untake'){
        if (this.detail.sourceType != "omni") {
          this.takeGoodsCodeShow = true;
        } else {
          this.isShowTakeMealsNumber = true;
          // 顯示取餐號
        }
      }

      // 櫃取顯示取貨碼
      if(this.detail.status=='ungridtake'){
        if (this.detail.sourceType != "omni") {
          this.ungridTakeCodeShow = true;
        } else {
          this.isShowTakeMealsNumber = true;
          // 顯示取餐號
        }
      }
    },
    contactShop() {
      let that = this;
      if (this.detail && this.detail.role && this.detail.role.phone) {
        uni.postMessage({
          data: {
            phone: this.detail.role.phone,
          },
        });
        uni.navigateBack();
      } else {
        Toast("當前商家暫未設定電話，請聯絡平台客服");
      }
    },
    contactExpress() {
      let that = this;
      if (
        this.detail &&
        this.detail.orderExpress &&
        this.detail.orderExpress.riderPhone
      ) {
        uni.postMessage({
          data: {
            phone: this.detail.orderExpress.riderPhone,
          },
        });
        uni.navigateBack();
      } else {
        Toast("當前物流士暫未設定電話，請聯絡平台客服");
      }
    },
    goShop() {
      uni.postMessage({
        data: {
          roleId: this.detail.role.id,
        },
      });
      uni.navigateBack();
    },
    getLocationDistance() {
      let lon1 = "";
      let lon2 = "";
      let lat1 = "";
      let lat2 = "";

      if (
        this.detail.type == "delivery" &&
        this.detail.orderExpress &&
        this.detail.orderExpress.riderLng
      ) {
        lon1 = this.detail.orderExpress.riderLng;
        lat1 = this.detail.orderExpress.riderLat;
        lon2 = this.detail.orderAddress.lng;
        lat2 = this.detail.orderAddress.lat;
      } else if (
        this.detail.type == "shop_take" &&
        this.detail.pickShop &&
        this.detail.pickShop.lng &&
        this.detail.orderAddress &&
        this.detail.orderAddress.lng
      ) {
        lon1 = this.detail.pickShop.lng;
        lat1 = this.detail.pickShop.lat;
        lon2 = this.detail.orderAddress.lng;
        lat2 = this.detail.orderAddress.lat;
      } else {
        return 16;
      }
      let PI = 3.14159265358979323; //圓周率
      let R = 6371229; //地球半徑

      let x, y, distance;
      let lonres = lon1 > lon2 ? lon1 - lon2 : lon2 - lon1;
      let latres = lat1 > lat2 ? lat1 - lat2 : lat2 - lat1;
      x = (lonres * PI * R * Math.cos((((lat1 + lat2) / 2) * PI) / 180)) / 180;
      y = ((lat2 - lat1) * PI * R) / 180;
      distance = parseInt(Math.hypot(x, y));

      let zoomSize = 18;
      if (distance > 0 && distance < 500) {
        zoomSize = 15;
      } else if (distance >= 500 && distance < 1000) {
        zoomSize = 16;
      } else {
        zoomSize = 17;
      }
      return zoomSize;
    },
    goBack() {
      console.log('back')
      uni.postMessage({
        data: {
          goback: 1,
        },
      });
      uni.navigateBack();
    },
    getGuessTime() {
      OrderApi.estimated(this.orderId)
        .then((res) => {
          if (res.code == 0 && res.data && res.data.estimatedTime) {
            let time = this.$util.formatDate(res.data.estimatedTime, "hh:mm");
            this.expressTime = `預計 ${time} 配送完成`;
            let expressingStr = `您的訂單已經送出，預計 ${time} 送達`;
            this.orderDescStr.expressing = expressingStr;
            this.shopTakeOrderDescStr.expressing = expressingStr;
          } else {
            Toast(res.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    },
    
  },
  components: {
    VueQr,
    orderDetailSpuItem
  },
  watch: {
    detail() {
      this.$nextTick(() => {
        let DOM = document.getElementsByClassName("order-detail")[0];
        this.orderDetailHeigt = DOM.offsetHeight + 100;
      });
    },
    nowTouchY: {
      handler(nowTouchY) {
        let y = nowTouchY - this.oldTouchY; // 滑動坐標
        this.y = y;
        if (this.moveHeight > -this.sysHeight) {
          this.isOpen = true;
        }
        if (this.isOpen) {
          // 是否是已經在最高的高度
          if (y + this.orginOffset > -160) {
            // 判斷是否是最底部
            this.moveHeight = -160;
          } else if (y + this.orginOffset <= -this.sysHeight) {
            // 判斷是否是最高高度
            this.moveHeight = -this.sysHeight - 90;
          } else {
            this.moveHeight = this.orginOffset + y - 90;
          }
        } else {
          // 滑動內容
          if (y > 0 && this.detailOffset >= 0) {
            // 黨內容在最頂部且向下滑動
            this.oldTouchY = nowTouchY;
            this.detailOffset = 0;
            this.isOpen = true;
          } else {
            if (
              this.orginDetailOffset + y >
              this.sysHeight - this.orderDetailHeigt
            ) {
              // 判斷是否是最底部
              this.detailOffset = this.orginDetailOffset + y;
            } else if (this.orginDetailOffset + y > 0) {
              // 判斷是否是最頂部
              this.isOpen = true;
            } else {
              this.detailOffset = this.sysHeight - this.orderDetailHeigt;
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    sysHeight() {
      let sysHeight = document.documentElement.clientHeight;
      return sysHeight;
    },
    barOpacity() {
      let num = ((Math.abs(this.moveHeight) - this.sysHeight) / 10 + 1).toFixed(
        2
      );
      if (num >= -60 && num <= 1) {
        return num;
      } else {
        return 0;
      }
    },
    isExitPackage(){
				if(!(this.detail&&this.detail.orderItemList&&this.detail.orderItemList.length>=1))return false;
				let exitPackage = this.detail.orderItemList.some(el=>el.type==1);
				return exitPackage
		},
    packageList(){
      if(!(this.detail&&this.detail.orderItemList&&this.detail.orderItemList.length>=1))return [];
      return this.detail.orderItemList.filter(el=>el.type==1);
    },
    spuList(){
      if(!(this.detail&&this.detail.orderItemList&&this.detail.orderItemList.length>=1))return [];
      return this.detail.orderItemList.filter(el=>el.type==0);
    },


  },
};
</script>

<style lang="less" scope>
.page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // 頭部遮罩欄
  .upToDown {
    width: 100%;
    height: 30px;
    animation: showZeroAlertPush 0.5s;
    @keyframes showZeroAlertPush {
      0% {
        transform: translateY(-30px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  .head-bar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: #fff;
  }
  .back-icon {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 10000;
    img {
      width: 30px;
      height: 30px;
    }
  }
  #map {
    width: 100vw;
    height: 100vh;
  }
  .mask {
    z-index: 5;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
  }
  .content {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .content::-webkit-scrollbar {
    display: none;
  }
  .order {
    margin-top: 70vh;
    z-index: 10;
    padding: 5px 12px 40px 12px;
    background-color: #f1f1f3;
    position: relative;
    // height: 100vh;
    // overflow: hidden;
    // transform: translateY(-250px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 40px);
    padding-bottom: calc(constant(safe-area-inset-bottom) + 40px);
    width: 100%;
    box-sizing: border-box;
    .order-detail {
      width: 100%;
      border-radius: 10px;
      .status {
        padding: 14px;
        padding-bottom: 0;
        border-radius: 10px;
        background-color: #fff;
        .title {
          font-size: 19px;
          font-weight: 700;
          min-height: 30px;
        }
        .time {
          margin-top: 6px;
          font-size: 13px;
          color: #656565;
        }
        .pin-code{
          color: #656565;
          padding-bottom: 20px;
          margin-top: 8px;
          font-size: 14px;
          span{
            color: #323232;
            font-size: 18px;
            padding-left: 3px;
          }
        }
        .goods-info{
          background-color: #FFFFFF;
          margin: 10px 10px;
          border-radius: 10px;
          padding: 5px 10px 0 10px;
        }

        .buttons {
          border-top: 0.5px solid #f3f3f3;
          padding: 5px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          & > div {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 17px;
            color: #008edc;
            font-weight: 700;
            img {
              width: 18px;
              margin-right: 6px;
            }
          }
        }
      }
      .spu {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 10px;
        // margin-bottom: 9px;
        padding: 0 14px;
        .store {
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #333;
          font-weight: 700;
          border-bottom: 0.5px solid #f3f3f3;
          img {
            width: 6px;
            margin-left: 6px;
            margin-top: 3px;
          }
        }

        .price-list {
          margin-top: 10px;
          border-top: 0.5px solid #f3f3f3;
          .discount {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 14px;
              color: #333;
            }
            .red{
              color: #F63C2C;
            }
            .blue{
              color: #008EDC;
            }
          }
        }
      }
      .detail {
        margin-top: 10px;
        background-color: #fff;
        border-radius: 10px;
        padding: 0 14px;
        .title {
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 700;
          color: #333;
          border-bottom: 0.5px solid #f3f3f3;
        }
        .t-r {
          text-align: right;
        }

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;

          .name {
            font-size: 14px;
            color: #333;
            width: 80px;
          }
          .value {
            color: #333;
            font-size: 12px;
            flex: 1;
          }
        }
        .address {
          .address-info {
            .detail-address {
              text-align: end;
              font-size: 12px;
              color: #333;
              display: flex;
              flex-direction: column;
            }
            .user-info {
              font-size: 12px;
              color: #333333;
              display: flex;
              justify-content: flex-end;
              margin-top: 3px;
              font-weight: bold;
              div + div {
                margin-left: 10px;
              }
            }
          }
        }
      }
      .shipping {
        margin-top: 10px;
        background-color: #fff;
        border-radius: 10px;
        padding: 0 14px;
        .title {
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 700;
          color: #333;
          border-bottom: 0.5px solid #f3f3f3;
        }
        .time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          color: #333;
          .name {
            font-size: 14px;
          }
          .value {
            font-size: 12px;
          }
        }
      }
    }
  }
}

// 取貨碼
.popup-wrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  .content-bg-box {
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;
    height: 299px;
    border-radius: 4px;
    .code-box {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid #888;
      margin-top: 20px;
      width: 177px;
      height: 177px;
    }
    .title {
      width: 100%;
      text-align: center;
      font-size: 17px;
      color: #222222;
      margin-top: 20px;
    }
    .order-number {
      width: 100%;
      text-align: center;
      font-size: 15px;
      color: #222222;
      margin-top: 5px;
    }
  }
  .trigger{
    background-color: rgb(97, 162, 216);  
    color: #fff;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

  }
  .close-icon {
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }
}
.map-poin {
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 5px;
    height: 15px;
  }
  .cursor {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
}
/deep/ .gm-style-iw-t {
  button {
    display: none !important;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80vw;
  height: 460px;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 20px;
  position: relative;
}
.close-icon {
  position: absolute;
  right: -3px;
  top: -3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f0f0f0;
  line-height: 20px;
  text-align: center;
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -49%);
  }
}
.name {
  font-size: 17px;
  font-weight: 700;
}
.take-meals-number {
  width: 159px;
  height: 159px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 33px;
  margin-bottom: 36px;
  line-height: 159px;
  // background-color:#008edc;
  background: linear-gradient(to top, #0078c8, #34aaf8);
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 2px;
}
.text {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 30px;
}
.btns {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  .button {
    width: 35vw;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
    font-size: 12px;
  }
  .close {
    color: #008edc;
    border: 1px solid #008edc;
  }
  .confirm {
    color: #fff;
    background-color: #008edc;
  }
}
.text-center {
  text-align: center;
}
.pb-5{
  padding-bottom: 5px;
}
.mt-5{
  margin-top: 5px;
}
.bold{
  font-weight: bold;
}
</style>
