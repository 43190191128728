<template>
  <div class="page">
    <div class="to-back" @click="toBack">
      <img src="@/static/image/ic_back_dart@2x.png" alt="" />
    </div>
    <div id="map"></div>
    <div class="shop-info" v-if="show">
      <div class="title">{{ locationArr[index].role.name }}</div>
      <div class="subhead">便利店</div>
      <div class="address">{{ locationArr[index].role.address }}</div>
      <div class="submit">
        <div class="button" :class="locationArr[index].status=='closed'?'closed':''" @click="submit">{{locationArr[index].status=='opening'?'選取':'休息中'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import MapService from "../../utils/map-service";
let addressIcon = require("@/static/image/of_ic_address_shop_m@2x.png");
let addressIconBig = require("@/static/image/of_ic_address_shop_m@3x.png");
import { Toast } from "vant";
import { ExOfShopApi } from "@/api";
import GoogleMap from "@/utils/google-map"
export default {
  name: "shop-address-page",
  data() {
    return {
      circle: null,
      mapService: null,
      shopTakeScope: 3000,
      locationArr: [],
      shopInfo: {},
      index: 0,
      show: false,
      markerArr: [],
      roleId: "",
      isInitMap:false
    };
  },
  created() {
    this.roleId = this.$route.query.roleId;
  },
  mounted() {
    GoogleMap.InitGoogle().then(res=>{
      this.isInitMap=true
      this.initMap();
    }).catch(err=>{
      console.log('err')
    })
  },
  methods: {
    toBack(){
      uni.navigateBack()
    },
    initMap() {
      let that = this;
      if (!this.shopInfo.lat) return;
      let maps = new google.maps.Map(document.getElementById("map"), {
        zoom: that.shopTakeScope > 3 ? 12 : 13,
        center: { lat: that.shopInfo.lat, lng: that.shopInfo.lng },
        disableDefaultUI: true,
        clickableIcons: false, //阻止點選開啟預設資訊視窗
      });
      this.mapService = new MapService(
        maps,
        new google.maps.LatLng(that.shopInfo.lat, that.shopInfo.lng)
      );
      this.circle = this.mapService.drawCircle({
        strokeColor: "#000000",
        strokeOpacity: 0.8,
        strokeWeight: 0,
        fillColor: "#9BC3FF",
        fillOpacity: 0.5,
        center: new google.maps.LatLng(that.shopInfo.lat, that.shopInfo.lng),
        radius: that.shopTakeScope * 1000,
      });
      let markers = [];
      if (!this.locationArr || this.locationArr.length < 1) {
        Toast("暫無可店取的全家門市");
        return;
      }
      this.locationArr.forEach((item, index) => {
        const marker = new google.maps.Marker({
          anchorPoint: new google.maps.Point(0, 0),
          // position: {
          //  anchorPoint: new google.maps.LatLng(item.lat, item.lng),
          //   // collisionBehavior:true
          // },
          position: new google.maps.LatLng(item.lat, item.lng),
          map: maps,
          icon: {
            url: addressIcon,
            scaledSize: new google.maps.Size(28, 32),
            origin: new google.maps.Point(0, 0), // origin
            // anchor: new google.maps.Point(0, 0),
          },
          zIndex: 1,
        });
        let marker1 = new google.maps.Marker({
          position: new google.maps.LatLng(item.lat, item.lng),
          collisionBehavior: true,
          map: maps,
          icon: {
            url: addressIcon,
            scaledSize: new google.maps.Size(0, 0),
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(0, -15),
          },
          label: {
            text: item.role.name,
            className: "icon",
          },
        });
        google.maps.event.addListener(marker, "click", (e) => {
          that.show = true;
          that.index = index;
        });
        google.maps.event.addListener(marker1, "click", (e) => {
          that.show = true;
          that.index = index;
        });
        that.markerArr.push({
          marker,
          markerText: marker1,
        });
      });
    },
    submit() {
      if(this.locationArr[this.index].status=='closed') return
      let that = this
      uni.postMessage({
        data: {
          address: that.locationArr[that.index].role.address||'',
          name: that.locationArr[that.index].role.name,
          roleId: that.locationArr[that.index].roleId,
        },
      });
      uni.navigateBack();
    },
  },
  watch: {
    index(index) {
      this.markerArr.forEach((item, i) => {
        if (i == index) {
          item.marker.setOptions({
            icon: {
              url: addressIconBig,
              scaledSize: new google.maps.Size(56, 64),
              origin: new google.maps.Point(0, 0), // origin
              // anchor: new google.maps.Point(0, 0),
            },
          });
        } else {
          item.marker.setOptions({
            icon: {
              url: addressIcon,
              scaledSize: new google.maps.Size(28, 32),
              origin: new google.maps.Point(0, 0), // origin
              // anchor: new google.maps.Point(0, 0),
            },
          });
        }
      });
    },
    roleId(roleId) {
      ExOfShopApi.rangeList({ roleId }).then((res) => {
        if (res.data.shopTakeScope) {
          this.shopTakeScope = res.data.shopTakeScope;
        }
        this.locationArr = res.data.shopList;
        this.shopInfo = res.data.shop;
        if (!res.data.shopList || res.data.shopList.length < 1) {
          // Toast('暫無可店取的全家門市');
        }
        // this.
      });
    },
    locationArr: {
      handler(locationArr) {
        if(this.isInitMap){
          this.initMap();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.point {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  background-color: #000;
}
.page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .to-back {
    z-index: 10;
    position: fixed;
    left: 15px;
    top: 57px;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 9px;
      height: 15px;
    }
  }
  .shop-info {
    width: 100%;
    padding: 22px 0;
    padding-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    color: #333;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    .title {
      padding: 0 17px;
      font-size: 19px;
      font-weight: 600;
    }
    .subhead {
      padding: 0 17px;
      font-size: 15px;
    }
    .address {
      padding: 0 17px;
      margin-top: 8px;
      font-size: 15px;
      color: #9a9a9a;
      margin-bottom: 28px;
    }
    .submit {
      box-shadow: 0px -1px 4px 0px rgba(209, 209, 209, 0.5);
      padding: 15px 16px 48px;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 47px;
        background-color: #008edc;
        color: #fff;
        font-size: 16px;
        border-radius: 8px;
        &.closed{
          background-color: #9a9a9a;
        }
      }
      // padding-top: 15px;
      // padding-bottom: 48px;
    }
  }
  #map {
    width: 100%;
    height: 100%;
  }
}
.icon {
  // position: absolute;
  // top:20px ;
  transform: translateY(100px);
}
</style>
