import ExOfShopApi from "./ex-of-shop-api"
import OrderApi from "./order-api"
import GoogleMapApi from './google-map-api'

export{
  ExOfShopApi,
  OrderApi,
  GoogleMapApi
}

export default{
  ExOfShopApi,
  OrderApi,
  GoogleMapApi
}