var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.item
      ? _c("div", { staticClass: "spu-item" }, [
          _vm.item.spuCover
            ? _c("img", { attrs: { src: _vm.item.spuCover } })
            : _vm._e(),
          _c("div", { staticClass: "prototype" }, [
            _c("div", { staticClass: "name-content" }, [
              _c("div", { staticClass: "name" }, [
                _c("span", [_vm._v(_vm._s(_vm.item.spuName))]),
              ]),
              _c("div", { staticClass: "price" }, [
                _c("span", [_vm._v("NT$ " + _vm._s(_vm.item.unitPrice))]),
              ]),
            ]),
            _c("div", { staticClass: "spu-prototype" }, [
              _vm._v(_vm._s(_vm.item.propertyValues)),
            ]),
            _c("div", { staticClass: "num-content" }, [
              _c("div", [
                _vm._v(" x" + _vm._s(_vm.item.quantity) + " "),
                _vm.item.refundQuantity && _vm.item.refundQuantity >= 1
                  ? _c("div", { staticClass: "refund-tag" }, [
                      _c("span", [
                        _vm._v("缺貨x" + _vm._s(_vm.item.refundQuantity)),
                      ]),
                      _vm._v(" 已退款 "),
                    ])
                  : _vm._e(),
              ]),
              _vm.item && _vm.item.promotionPrice
                ? _c("div", [
                    _c("span", { staticClass: "label" }, [_vm._v("小計：")]),
                    _c("span", { staticClass: "promotion-price" }, [
                      _vm._v("NT$ " + _vm._s(_vm.item.promotionPrice)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }