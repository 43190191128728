<template>
    <div>

        <div class="spu-item" v-if="item">
            <img :src="item.spuCover" v-if="item.spuCover" />
            <div class="prototype">
                <div class="name-content">
                    <div class="name">
                        <span>{{ item.spuName }}</span>
                    </div>

                    <div class="price">
                        <span >NT$ {{ item.unitPrice }}</span>
                    </div>
                </div>
                <div class="spu-prototype">{{ item.propertyValues }}</div>
                <div class="num-content" >
                    <div>
                        x{{ item.quantity }}
                        <div
                            class="refund-tag"
                            v-if="item.refundQuantity && item.refundQuantity >= 1"
                            >
                            <span>缺貨x{{ item.refundQuantity }}</span>
                            已退款
                        </div>
                    </div>

                    <div  v-if="item&&item.promotionPrice">
                        <span class="label">小計：</span>
                        <span class="promotion-price">NT$ {{item.promotionPrice}}</span>
                    </div>

                </div>
            </div>
        </div>
        
    
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
    },
    computed: {
    },
    methods: {

    }
}

</script>

<style scoped lang="less">
    .spu-item {
        min-height: 65px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        img {
            width: 48px;
            flex: 0 0 48px;
            height: 48px;
            border-radius: 6px;
            margin-right: 10px;
        }
        .prototype {
            flex: 1;
            .name-content {
                display: flex;
                align-items: flex-start;
                .name{
                    font-size: 14px;
                    color: #333;
                    flex: 1;
                }
                .price{
                    margin-left: 3px;
                    font-weight: bold;
                    color: #323232;
                    font-size: 14px;
                }
            }
            .spu-prototype {
              color: #888b94;
              font-size: 12px;
              min-height: 10px;
            }
            .num-content {
              color: #888b94;
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 5px;

              .promotion-price{
                color: #EF6500;
                font-weight: bold;
                font-size: 16px;
              }
              .label{
				color: #323232;
                font-size: 14px;
				font-weight: bold;
			}

              .refund-tag {
                display: inline-block;
                font-size: 9px;
                margin-left: 10px;
                border-radius: 15px;
                background-color: #fff;
                padding: 2px 5px;
                color: #f63c2c;
                border: 0.5px solid #f63c2c;
              }
            }
        }
        .price-num {
            font-size: 14px;
            color: #333;
            font-weight: 700;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            margin-left: 5px;
            border: 1px solid #000;
            .throught-line{
              text-decoration: line-through;
            }
        }
    }
</style>