var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      attrs: { id: "map" },
      on: {
        touchmove: _vm.touchmove,
        touchstart: _vm.touchstart,
        touchend: _vm.touchend,
      },
    }),
    _c("div", { staticClass: "center", class: _vm.isTouching ? "touch" : "" }, [
      _c(
        "div",
        { staticClass: "icon", class: _vm.isRange ? "" : "is-not-range" },
        [_vm._v(_vm._s(_vm.name))]
      ),
    ]),
    _c("div", { staticClass: "message" }, [
      _vm.showToBegin
        ? _c("div", { staticClass: "tobegin", on: { click: _vm.toBegin } }, [
            _c("img", {
              attrs: {
                src: require("@/static/image/of_ic_address_recovery@2x.png"),
                alt: "",
              },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "title" }, [_vm._v("確認派送地址")]),
      _c("div", { staticClass: "toast" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.isRange
                ? "移動圖釘以突出顯示正確的大門或入口，以便物流士更快的派送訂單商品。"
                : "您距離此地址較遠。"
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "button",
          class: _vm.isRange ? "" : "gray",
          on: { click: _vm.changeAddress },
        },
        [_vm._v(" 確認位置 ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }