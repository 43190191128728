var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "to-back", on: { click: _vm.toBack } }, [
      _c("img", {
        attrs: { src: require("@/static/image/ic_back_dart@2x.png"), alt: "" },
      }),
    ]),
    _c("div", { attrs: { id: "map" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }