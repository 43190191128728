import {GoogleMapApi} from "@/api";

function InitGoogle() {
  return new Promise((resolve, reject) => {
    if (window.google) {
      resolve();
      // 未載入谷歌地圖API，則先載入API再初始化
    } else {
      // 載入谷歌地圖和UI元件
      loadJS()
        .then(() => {
            resolve();
        })
        .catch((a) => {
          console.log(a,'aaa')
          reject();
        });
    }
  });
}

function loadJS() {
  console.log('loadJS')
  return new Promise((resolve, reject) => {
    fetch('./static/21a300d4a9a2cf55e4ffac1750acbc01.json').then(res => res.json()).then(data => {
      console.log('data',data)
      let key = data.key
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "googleMap";
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        key +
        "&libraries=places&libraries=drawing,places,geometry,spherical";
      document.getElementsByTagName("body")[0].appendChild(script);
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(2222);
      };
    })
    // GoogleMapApi.clientSideKey().then((res) => {
     
    //   // let app = document.getElementById('app')
    //   // parent = app.parentNode;
    //   // if (parent.lastChild == app) {
    //   //     parent.appendChild(script);
    //   // } else {
    //   //     parent.insertBefore(script, app.nextSibling);
    //   // }
    // });
  });
}

export default{
  InitGoogle
}