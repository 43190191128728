import apiManager from './manager/api-manager'

export default {
    
    /**
     * 商家-分頁
     * @param {Object} areaId：地區id
     * @param {Object} categoryId：商家分類(角色分類ID)
     * @param {Object} keyword：關鍵字搜尋
     * @param {Object} lat：緯度
     * @param {Object} lng：經度
     * @param {Object} page：頁數
     * @param {Object} pageSize：每頁條數
     * @param {Object} shopType：商家型別
     * @param {Object} takeMeal：取餐方式(self_take-自取、shop_take-店取、delivery-外送)
     */
    page(query) {
        const data = {
            url: `/api/of/shop/v1/page`,
            params: query,
        }
        return apiManager.get(data)
    },


    /**
     * 獲取店取商家範圍
     * @param {Object} roleId：商家角色id
     */
    rangeList(roleId) {
        const data = {
            url: `/api/of/shop/v1/range-list`,
            params: roleId,
        }
        return apiManager.get(data)
    },


    /**
     * 商家詳情
     * @param {Object} roleId：商家角色id
     */
    detail(roleId) {
        const data = {
            url: `/api/of/shop/v1/detail`,
            params: roleId,
        }
        return apiManager.get(data)
    },


    /**
     * 獲取全家超市
     * @param {Object} lat：緯度
     * @param {Object} lng：經度
     */
    familySupermarket(query) {
        const data = {
            url: `/api/of/shop/v1/family-supermarket`,
            params: query,
        }
        return apiManager.get(data)
    },


}
