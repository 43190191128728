import apiManager from "./manager/api-manager";
import { APPLICATION_JSON } from "@/api/manager/config.js";

export default {
    
    /**
     * Fami支付
     * @param {Object} orderNumber：訂單編號
     */
    famiPay(orderNumber) {
        const data = {
            url: `/api/of/order/v1/fami-pay`,
            params: orderNumber,
        }
        return apiManager.post(data)
    },


    /**
     * 訂單時間線
     * @param {Object} id：id
     */
    timelineList(id) {
        const data = {
            url: `/api/of/order/v1/${id}/timeline`,
        }
        return apiManager.get(data)
    },


    /**
     * 訂單退款詳情列表
     * @param {Object} id：訂單id
     */
    refundList(id) {
        const data = {
            url: `/api/of/order/v1/${id}/refund-list`,
        }
        return apiManager.get(data)
    },


    /**
     * 訂單預覽-購物車商品
     * @param {Object} cartIdList：購物車id
     */
    preview(cartIdList) {
        const data = {
            url: `/api/of/order/v1/preview`,
            params: cartIdList,
        }
        return apiManager.get(data)
    },


    /**
     * 訂單列表
     * @param {Object} hasRefund：是否退款（1-退款）
     * @param {Object} page：頁數
     * @param {Object} pageSize：每頁條數
     * @param {Object} status：訂單狀態(unpay=待支付，unaccept=待接單，unready=待備貨，unexpress=待配送，expressing=配送中，untake=待取貨，finish=已完成，cancel=已取消)
     * @param {Object} type：訂單型別(self_take=自取，shop_take=門市取，delivery=外送)
     */
    page(query) {
        const data = {
            url: `/api/of/order/v1/page`,
            params: query,
        }
        return apiManager.get(data)
    },


    /**
     * 建立訂單
     * @param {Object} form：form
     */
    add(form) {
        const data = {
            url: `/api/of/order/v1`,
            params: form,
            header:APPLICATION_JSON
        }
        return apiManager.post(data)
    },


    /**
     * 訂單取消
     * @param {Object} id：訂單id
     */
    cancel(id) {
        const data = {
            url: `/api/of/order/v1/${id}/cancel`,
        }
        return apiManager.put(data)
    },


    /**
     * 訂單詳情
     * @param {Object} id：id
     */
    detail(id) {
        const data = {
            url: `/api/of/order/v1/${id}`,
        }
        return apiManager.get(data)
    },
	 /**
	 * 根據訂單編號查詢訂單
	 * @param {Object} orderNumber：訂單編號
	 */
	orderNumber(orderNumber) {
		const data = {
			url: `/api/of/order/v1/order-number`,
			params: orderNumber,
		}
		return apiManager.get(data)
	},

     /**
     * 獲取訂單物流士位置)
     * @param {Object} id：訂單id
     */
      expressPath(id) {
        const data = {
            url: `/api/of/order/v1/${id}/express-path`,
        }
        return apiManager.get(data)
    },
        /**
     * 獲取訂單物流士資訊
     * @param {Object} id：訂單id
     */
     express(id) {
        const data = {
            url: `/api/of/order/v1/${id}/express`,
        }
        return apiManager.get(data)
    },
     /**
     * 獲取送餐到達的預估時間
     * @param {string} id：id
     */
      estimated(id) {
        const data = {
            url: `/api/of/order/v1/${id}/estimated-time`,
        }
        return apiManager.get(data)
    },
     /**
     * 獲取送餐到達的預估時間
     * @param {string} id：id
     */
      take(params) {
          console.log(params,'params')
        const data = {
            url: `/api/of/order/v1/${params.id}/take`,
            params
        }
        return apiManager.put(data)
    },


}
